// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Cx from "rescript-classnames/src/Cx.res.js";
import * as H1 from "../../../styleguide/components/Heading/H1.res.js";
import * as ID from "../../../libs/ID.res.js";
import * as Link from "../../../styleguide/components/Link/Link.res.js";
import * as Hooks from "../../../libs/Hooks.res.js";
import * as React from "react";
import * as Control from "../../../styleguide/components/Control/Control.res.js";
import * as Popover from "../../../styleguide/components/Popover/Popover.res.js";
import * as $$Promise from "../../../bindings/Promise.res.js";
import * as Spinner from "../../../styleguide/components/Spinner/Spinner.res.js";
import * as Belt_Set from "rescript/lib/es6/belt_Set.js";
import * as Checkbox from "../../../styleguide/forms/Checkbox/Checkbox.res.js";
import * as Dropdown from "../../../styleguide/forms/Dropdown/Dropdown.res.js";
import * as IconMenu from "../../../styleguide/icons/IconMenu.res.js";
import * as Container from "../../../styleguide/components/Container/Container.res.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Pagination from "../../../styleguide/components/Pagination/Pagination.res.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as IconSorting from "../../../styleguide/icons/IconSorting.res.js";
import * as SearchField from "../../../styleguide/forms/SearchField/SearchField.res.js";
import * as Api_BlogPost from "../../../api/blog-posts/Api_BlogPost.res.js";
import * as SentryLogger from "../../../loggers/SentryLogger.res.js";
import * as ReactDebounce from "rescript-debounce-react/src/ReactDebounce.res.js";
import * as BlogPostNavbar from "../common/navbar/BlogPostNavbar.res.js";
import * as Routes_BlogPost from "../../../routes/common/Routes_BlogPost.res.js";
import * as BlogPostsOptions from "../dashboard/BlogPostsOptions.res.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as BlogPostsDashboardAnalyticsScss from "./BlogPostsDashboardAnalytics.scss";

var css = BlogPostsDashboardAnalyticsScss;

var initialState_sortBy = {
  NAME: "TotalViews",
  VAL: "Desc"
};

var initialState_categoryFilter = ID.$$Set.make();

var initialState = {
  status: "FetchingBlogPosts",
  currentPage: 1,
  totalPages: 1,
  sortBy: initialState_sortBy,
  search: "",
  categoryFilter: initialState_categoryFilter
};

function BlogPostsDashboardAnalytics$default(props) {
  var categories = props.categories;
  var container = React.useRef(null);
  var searchBlogPosts = ReactDebounce.useDebounced(300, (function (param) {
          param.dispatch({
                TAG: "PerformSearch",
                _0: param.state.search
              });
        }));
  var match = Hooks.useReducer(initialState, (function (state, action) {
          if (typeof action !== "object") {
            if (action === "FetchBlogPosts") {
              return {
                      TAG: "SideEffects",
                      _0: (function (param) {
                          var dispatch = param.dispatch;
                          var state = param.state;
                          var match = state.search;
                          var tmp = match === "" ? undefined : state.search;
                          $$Promise.wait(Api_BlogPost.fetchBlogPostsAnalytics(state.currentPage, tmp, state.sortBy, Belt_Set.toList(state.categoryFilter)), (function (x) {
                                  if (x.TAG === "Ok") {
                                    return dispatch({
                                                TAG: "SucceedBlogPostsFetch",
                                                _0: x._0
                                              });
                                  }
                                  SentryLogger.error1({
                                        rootModule: "BlogPostsDashboardAnalytics",
                                        subModulePath: /* [] */0,
                                        value: "default",
                                        fullPath: "BlogPostsDashboardAnalytics.default"
                                      }, "BlogPostsDashboardAnalytics::FetchBlogPosts::Error", [
                                        "Error",
                                        x._0
                                      ]);
                                  dispatch("FailBlogPostsFetch");
                                }));
                        })
                    };
            } else {
              return {
                      TAG: "Update",
                      _0: {
                        status: "FailedBlogPostsFetch",
                        currentPage: state.currentPage,
                        totalPages: state.totalPages,
                        sortBy: state.sortBy,
                        search: state.search,
                        categoryFilter: state.categoryFilter
                      }
                    };
            }
          }
          switch (action.TAG) {
            case "SucceedBlogPostsFetch" :
                var res = action._0;
                return {
                        TAG: "UpdateWithSideEffects",
                        _0: {
                          status: {
                            TAG: "Ready",
                            _0: res.blogPosts
                          },
                          currentPage: res.currentPage,
                          totalPages: res.totalPages,
                          sortBy: state.sortBy,
                          search: state.search,
                          categoryFilter: state.categoryFilter
                        },
                        _1: (function (param) {
                            Belt_Option.map(Caml_option.nullable_to_opt(container.current), (function (prim) {
                                    return prim.scrollTop;
                                  }));
                          })
                      };
            case "UpdatePage" :
                var nextPage = action._0;
                var match = state.status;
                if (typeof match !== "object" && match === "FetchingBlogPosts") {
                  return "NoUpdate";
                }
                if (state.currentPage !== nextPage) {
                  return {
                          TAG: "UpdateWithSideEffects",
                          _0: {
                            status: "FetchingBlogPosts",
                            currentPage: nextPage,
                            totalPages: state.totalPages,
                            sortBy: state.sortBy,
                            search: state.search,
                            categoryFilter: state.categoryFilter
                          },
                          _1: (function (param) {
                              param.dispatch("FetchBlogPosts");
                            })
                        };
                } else {
                  return "NoUpdate";
                }
            case "SortBy" :
                var sort = action._0;
                var match$1 = state.status;
                if (typeof match$1 !== "object" && match$1 === "FetchingBlogPosts") {
                  return "NoUpdate";
                }
                var tmp;
                if (sort === "ThisWeek") {
                  var match$2 = state.sortBy;
                  tmp = typeof match$2 === "object" && match$2.NAME === "ThisWeek" && match$2.VAL === "Desc" ? ({
                        NAME: "ThisWeek",
                        VAL: "Asc"
                      }) : ({
                        NAME: "ThisWeek",
                        VAL: "Desc"
                      });
                } else if (sort === "Title") {
                  var match$3 = state.sortBy;
                  tmp = typeof match$3 === "object" && match$3.NAME === "Title" && match$3.VAL === "Desc" ? ({
                        NAME: "Title",
                        VAL: "Asc"
                      }) : ({
                        NAME: "Title",
                        VAL: "Desc"
                      });
                } else if (sort === "TotalViews") {
                  var match$4 = state.sortBy;
                  tmp = typeof match$4 === "object" && match$4.NAME === "TotalViews" && match$4.VAL === "Desc" ? ({
                        NAME: "TotalViews",
                        VAL: "Asc"
                      }) : ({
                        NAME: "TotalViews",
                        VAL: "Desc"
                      });
                } else if (sort === "LastWeek") {
                  var match$5 = state.sortBy;
                  tmp = typeof match$5 === "object" && match$5.NAME === "LastWeek" && match$5.VAL === "Desc" ? ({
                        NAME: "LastWeek",
                        VAL: "Asc"
                      }) : ({
                        NAME: "LastWeek",
                        VAL: "Desc"
                      });
                } else {
                  var match$6 = state.sortBy;
                  tmp = typeof match$6 === "object" && match$6.NAME === "Category" && match$6.VAL !== "Desc" ? ({
                        NAME: "Category",
                        VAL: "Desc"
                      }) : ({
                        NAME: "Category",
                        VAL: "Asc"
                      });
                }
                return {
                        TAG: "UpdateWithSideEffects",
                        _0: {
                          status: "FetchingBlogPosts",
                          currentPage: state.currentPage,
                          totalPages: state.totalPages,
                          sortBy: tmp,
                          search: state.search,
                          categoryFilter: state.categoryFilter
                        },
                        _1: (function (param) {
                            param.dispatch("FetchBlogPosts");
                          })
                      };
            case "UpdateSearchInput" :
                return {
                        TAG: "UpdateWithSideEffects",
                        _0: {
                          status: state.status,
                          currentPage: state.currentPage,
                          totalPages: state.totalPages,
                          sortBy: state.sortBy,
                          search: action._0,
                          categoryFilter: state.categoryFilter
                        },
                        _1: searchBlogPosts
                      };
            case "PerformSearch" :
                var match$7 = state.status;
                if (typeof match$7 !== "object" && match$7 === "FetchingBlogPosts") {
                  return "NoUpdate";
                }
                if (action._0 !== state.search) {
                  return "NoUpdate";
                } else {
                  return {
                          TAG: "UpdateWithSideEffects",
                          _0: {
                            status: "FetchingBlogPosts",
                            currentPage: state.currentPage,
                            totalPages: state.totalPages,
                            sortBy: state.sortBy,
                            search: state.search,
                            categoryFilter: state.categoryFilter
                          },
                          _1: (function (param) {
                              param.dispatch("FetchBlogPosts");
                            })
                        };
                }
            case "ToggleCategoryFilter" :
                var categoryId = action._0;
                var match$8 = state.status;
                if (typeof match$8 !== "object" && match$8 === "FetchingBlogPosts") {
                  return "NoUpdate";
                }
                var categoryFilter = Belt_Set.has(state.categoryFilter, categoryId) ? Belt_Set.remove(state.categoryFilter, categoryId) : Belt_Set.add(state.categoryFilter, categoryId);
                return {
                        TAG: "UpdateWithSideEffects",
                        _0: {
                          status: "FetchingBlogPosts",
                          currentPage: state.currentPage,
                          totalPages: state.totalPages,
                          sortBy: state.sortBy,
                          search: state.search,
                          categoryFilter: categoryFilter
                        },
                        _1: (function (param) {
                            param.dispatch("FetchBlogPosts");
                          })
                      };
            
          }
        }));
  var dispatch = match[1];
  var state = match[0];
  React.useEffect((function () {
          dispatch("FetchBlogPosts");
        }), []);
  var len = categories.length;
  var tmp;
  if (len !== 1 && len !== 0) {
    var x = Belt_Set.size(state.categoryFilter);
    tmp = JsxRuntime.jsxs(Dropdown.make, {
          children: [
            JsxRuntime.jsx(Dropdown.Trigger.make, {
                  className: css.dropdown,
                  children: x !== 0 ? (
                      x !== 1 ? String(x) + " Categories" : "1 Category"
                    ) : "All Categories"
                }),
            JsxRuntime.jsx(Dropdown.Body.make, {
                  position: {
                    TAG: "Below",
                    _0: "LeftEdge"
                  },
                  className: css.dropdownBody,
                  children: Belt_Array.map(categories, (function (category) {
                          var id = "blogPost-categories-filter--category-" + ID.toString(category.id);
                          return JsxRuntime.jsxs("div", {
                                      children: [
                                        JsxRuntime.jsx(Checkbox.make, {
                                              id: id,
                                              size: "SM",
                                              checked: Belt_Set.has(state.categoryFilter, category.id),
                                              onChange: (function (param) {
                                                  dispatch({
                                                        TAG: "ToggleCategoryFilter",
                                                        _0: category.id
                                                      });
                                                })
                                            }),
                                        JsxRuntime.jsx("label", {
                                              children: category.title,
                                              className: css.label,
                                              htmlFor: id
                                            })
                                      ],
                                      className: css.dropdownBodyRow
                                    }, id);
                        }))
                })
          ]
        });
  } else {
    tmp = null;
  }
  var match$1 = state.sortBy;
  var match$2 = state.sortBy;
  var match$3 = state.sortBy;
  var match$4 = state.sortBy;
  var match$5 = state.sortBy;
  var blogPosts = state.status;
  var tmp$1;
  if (typeof blogPosts !== "object") {
    tmp$1 = blogPosts === "FetchingBlogPosts" ? JsxRuntime.jsx("div", {
            children: JsxRuntime.jsx(Spinner.make, {
                  size: "XL",
                  color: "Teal"
                }),
            className: Cx.cx([
                  css.tableBody,
                  css.tableBodyMinHeight
                ])
          }) : JsxRuntime.jsx("div", {
            children: "Something went wrong during the search",
            className: Cx.cx([
                  css.tableBody,
                  css.tableBodyMinHeight,
                  css.fetchError
                ])
          });
  } else {
    var blogPosts$1 = blogPosts._0;
    tmp$1 = JsxRuntime.jsx("div", {
          children: blogPosts$1.length !== 0 ? Belt_Array.map(blogPosts$1, (function (blogPost) {
                    var len = categories.length;
                    return JsxRuntime.jsxs("div", {
                                children: [
                                  JsxRuntime.jsx("div", {
                                        children: JsxRuntime.jsx(Link.make, {
                                              href: Routes_BlogPost.Dashboard.edit(blogPost.id),
                                              children: blogPost.title
                                            }),
                                        className: css.blogPost
                                      }),
                                  len !== 1 && len !== 0 ? JsxRuntime.jsx("div", {
                                          children: blogPost.category,
                                          className: css.category
                                        }) : null,
                                  JsxRuntime.jsx("div", {
                                        children: String(blogPost.ahoyEventsCount),
                                        className: css.totalViews
                                      }),
                                  JsxRuntime.jsx("div", {
                                        children: String(blogPost.thisWeek),
                                        className: css.thisWeek
                                      }),
                                  JsxRuntime.jsx("div", {
                                        children: String(blogPost.lastWeek),
                                        className: css.lastWeek
                                      }),
                                  JsxRuntime.jsx("div", {
                                        children: JsxRuntime.jsxs(Popover.make, {
                                              children: [
                                                JsxRuntime.jsx(Popover.Trigger.make, {
                                                      children: JsxRuntime.jsx(Control.make, {
                                                            children: JsxRuntime.jsx(IconMenu.make, {
                                                                  size: "MD",
                                                                  color: "Teal"
                                                                })
                                                          })
                                                    }),
                                                JsxRuntime.jsxs(Dropdown.Body.make, {
                                                      position: {
                                                        TAG: "Below",
                                                        _0: "RightEdge"
                                                      },
                                                      className: css.dropdownBody,
                                                      children: [
                                                        JsxRuntime.jsx(Control.make, {
                                                              className: css.dropdownBodyRow,
                                                              children: JsxRuntime.jsx(Link.make, {
                                                                    href: Routes_BlogPost.Dashboard.edit(blogPost.id),
                                                                    className: css.dropdownBodyLink,
                                                                    children: "Edit"
                                                                  })
                                                            }),
                                                        JsxRuntime.jsx(Control.make, {
                                                              className: css.dropdownBodyRow,
                                                              children: JsxRuntime.jsx(Link.make, {
                                                                    href: Routes_BlogPost.show(blogPost.slug),
                                                                    className: css.dropdownBodyLink,
                                                                    children: "View"
                                                                  })
                                                            })
                                                      ]
                                                    })
                                              ]
                                            }),
                                        className: css.blogPostMenu
                                      })
                                ],
                                className: Cx.cx([
                                      css.row,
                                      css.gridColumns
                                    ])
                              }, ID.toString(blogPost.id));
                  })) : JsxRuntime.jsx("div", {
                  children: "No blog posts where found matching that criteria.",
                  className: Cx.cx([
                        css.tableBody,
                        css.tableBodyMinHeight,
                        css.fetchError
                      ])
                }),
          className: css.tableBody
        });
  }
  return JsxRuntime.jsxs("div", {
              children: [
                JsxRuntime.jsx(BlogPostNavbar.make, {
                      selectedCategory: "Analytics"
                    }),
                JsxRuntime.jsxs(Container.make, {
                      className: css.layout,
                      setRef: Caml_option.some(container),
                      children: [
                        JsxRuntime.jsxs("div", {
                              children: [
                                JsxRuntime.jsx(H1.make, {
                                      className: css.title,
                                      children: "Blog Post Analytics"
                                    }),
                                JsxRuntime.jsxs("div", {
                                      children: [
                                        JsxRuntime.jsx(SearchField.make, {
                                              id: "blogPosts-search",
                                              value: state.search,
                                              inputClassName: css.searchField,
                                              onChange: (function ($$event) {
                                                  dispatch({
                                                        TAG: "UpdateSearchInput",
                                                        _0: $$event.target.value
                                                      });
                                                })
                                            }),
                                        tmp,
                                        JsxRuntime.jsx(BlogPostsOptions.make, {})
                                      ],
                                      className: css.headerControls
                                    })
                              ],
                              className: css.header
                            }),
                        JsxRuntime.jsxs("div", {
                              children: [
                                JsxRuntime.jsxs("div", {
                                      children: [
                                        JsxRuntime.jsx(Control.make, {
                                              onClick: (function (param) {
                                                  dispatch({
                                                        TAG: "SortBy",
                                                        _0: "Title"
                                                      });
                                                }),
                                              children: JsxRuntime.jsxs("div", {
                                                    children: [
                                                      "Blog Post",
                                                      JsxRuntime.jsx(IconSorting.make, {
                                                            size: "XXS",
                                                            direction: typeof match$1 === "object" && match$1.NAME === "Title" ? match$1.VAL : undefined
                                                          })
                                                    ],
                                                    className: Cx.cx([
                                                          css.blogPost,
                                                          css.tableHeaderCell
                                                        ])
                                                  })
                                            }),
                                        JsxRuntime.jsx(Control.make, {
                                              className: css.controlLeftAlign,
                                              onClick: (function (param) {
                                                  dispatch({
                                                        TAG: "SortBy",
                                                        _0: "Category"
                                                      });
                                                }),
                                              children: JsxRuntime.jsxs("div", {
                                                    children: [
                                                      "Category",
                                                      JsxRuntime.jsx(IconSorting.make, {
                                                            size: "XXS",
                                                            direction: typeof match$2 === "object" && match$2.NAME === "Category" ? match$2.VAL : undefined
                                                          })
                                                    ],
                                                    className: Cx.cx([
                                                          css.category,
                                                          css.tableHeaderCell
                                                        ])
                                                  })
                                            }),
                                        JsxRuntime.jsx(Control.make, {
                                              className: css.controlLeftAlign,
                                              onClick: (function (param) {
                                                  dispatch({
                                                        TAG: "SortBy",
                                                        _0: "TotalViews"
                                                      });
                                                }),
                                              children: JsxRuntime.jsxs("div", {
                                                    children: [
                                                      "Page Views",
                                                      JsxRuntime.jsx(IconSorting.make, {
                                                            size: "XXS",
                                                            direction: typeof match$3 === "object" && match$3.NAME === "TotalViews" ? match$3.VAL : undefined
                                                          })
                                                    ],
                                                    className: Cx.cx([
                                                          css.totalViews,
                                                          css.tableHeaderCell
                                                        ])
                                                  })
                                            }),
                                        JsxRuntime.jsx(Control.make, {
                                              className: css.controlLeftAlign,
                                              onClick: (function (param) {
                                                  dispatch({
                                                        TAG: "SortBy",
                                                        _0: "ThisWeek"
                                                      });
                                                }),
                                              children: JsxRuntime.jsxs("div", {
                                                    children: [
                                                      "This Week",
                                                      JsxRuntime.jsx(IconSorting.make, {
                                                            size: "XXS",
                                                            direction: typeof match$4 === "object" && match$4.NAME === "ThisWeek" ? match$4.VAL : undefined
                                                          })
                                                    ],
                                                    className: Cx.cx([
                                                          css.thisWeek,
                                                          css.tableHeaderCell
                                                        ])
                                                  })
                                            }),
                                        JsxRuntime.jsx(Control.make, {
                                              className: css.controlLeftAlign,
                                              onClick: (function (param) {
                                                  dispatch({
                                                        TAG: "SortBy",
                                                        _0: "LastWeek"
                                                      });
                                                }),
                                              children: JsxRuntime.jsxs("div", {
                                                    children: [
                                                      "Last Week",
                                                      JsxRuntime.jsx(IconSorting.make, {
                                                            size: "XXS",
                                                            direction: typeof match$5 === "object" && match$5.NAME === "LastWeek" ? match$5.VAL : undefined
                                                          })
                                                    ],
                                                    className: Cx.cx([
                                                          css.thisWeek,
                                                          css.tableHeaderCell
                                                        ])
                                                  })
                                            })
                                      ],
                                      className: Cx.cx([
                                            css.row,
                                            css.gridColumns,
                                            css.tableHeader
                                          ])
                                    }),
                                tmp$1
                              ],
                              className: css.table
                            }),
                        state.totalPages > 1 ? JsxRuntime.jsx("div", {
                                children: JsxRuntime.jsx(Pagination.make, {
                                      currentPage: state.currentPage,
                                      totalPages: state.totalPages,
                                      onPageClick: (function (x) {
                                          dispatch({
                                                TAG: "UpdatePage",
                                                _0: x
                                              });
                                        })
                                    }),
                                className: css.pagination
                              }) : null
                      ]
                    })
              ]
            });
}

var $$default = BlogPostsDashboardAnalytics$default;

export {
  css ,
  initialState ,
  $$default as default,
}
/* css Not a pure module */
